.main-section {
    background-color: #ebe6e6;
    // padding: 5px 35px;
    min-height: 100vh;

    &-content {
        margin: 1.5%;
        padding: 10px;
        min-height: 80vh;
        border-radius: 20px;
    }
}

.bg-app-primary {
    background-color: $primary-color;
    color: #fff;

    &:hover {
        color: #fff !important;
        background-color: #d24b19 !important;
    }
}

.cp {
    cursor: pointer;
}

.text-app-blue {
    color: $primary-blue;
}

.fw-b {
    font-weight: 600
}

.color-app-primary {
    color: $primary-color;
}

.ant-table-filter-dropdown-btns {

    button:not(:first-of-type) {
        background-color: $primary-color;

        &:hover {
            background-color: orangered !important;
        }
    }

    button:first-of-type {
        color: $primary-color;

        &:hover {
            color: orangered !important;

        }
    }
}