.sidebar {

    background: #2f243a;
    height: 100vh;
    max-height: 100vh;

    .menu-sidebar {
        background-color: rgb(47, 36, 58);
        font-size: 16px;
        margin-left: 8px;
    }

    .sidebar-container {

        .dropdown-menu-item-devider {
            opacity: 0.1;
        }

        .ant-menu-item-selected {
            background-color: #513F4E;
            height: 40px;
            border-radius: 12px;
        }
    }
}