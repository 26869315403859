.main-section-header {
    height: 70px;
    background-color: #ebe6e6;
    position: sticky;
    top: 0;
    z-index: 999 !important;

    .role {
        color: #777;
        font-weight: bolder;
        font-size: 14px;
    }

    .text-header {
        color: #2f243a;
        font-size: 24px;
        font-weight: 600;
    }

    .bell-button {
        background: #ebe6e6;
        border-radius: 99px;
        padding: 14px;
        transition: background-color 0.3s;
        border: 2px solid #ffffff;

        &:hover {
            background: rgba(255, 255, 255, 0.40);
        }

        &:active {
            background: white;
        }
    }

    .userImage-button {
        border-radius: 99px;
        border: 2px solid #ffffff;
        width: 50px;
        height: 50px;
    }
}